import React from "react";
import f2fLogo from "./../../Images/face2FaceLogo-white.png";
import linkedin from "./../../Images/linkedin.png";
import facebook from "./../../Images/facebook.png";
import instagram from "./../../Images/instagram.png";
import { animateScroll as scroll } from 'react-scroll';
import "./style.scss";
import $ from 'jquery';

export default class Index extends React.Component {
  componentDidMount() {
    // Back to top button
    $(window).scroll(function () {
      if ($(this).scrollTop() > 100) {
        $('.back-to-top').fadeIn('slow');
      } else {
        $('.back-to-top').fadeOut('slow');
      }
    });
  }

  scrollToTop() {
    scroll.scrollToTop(100);
  }
  render() {
    return (
      <section className="footer-container" style={{ backgroundColor: "#0B327D" }}>
        <div className="container">
          <div className="row pt-5 pb-2">
            <div className="col-lg-4 col-md-4">
              <div className="widget-lpinfo">
                <img style={{ cursor: 'pointer' }} onClick={this.scrollToTop} className="brand-logo-footer" src={f2fLogo} width="50%" alt="f2f" />
              </div>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard
            </p>
            </div>
            <div className="col-lg-3 offset-lg-1 offset-md-1 col-md-3">
              <div className="widget-lpinfo">
                <h5>Reach us on</h5>
              </div>
              <p>
                RWSolutions Ltd.
                Unit 1406, 14F, The Broadway,
                54-62 Lockhart Road, Wan Chai, Hong Kong
            </p>
              <p>info@face2facescheduler.com</p>
            </div>
            {/*<div className="col-lg-3 col-md-3">
            <div className="widget-lpinfo">
              <h5>Navigate</h5>
            </div>     
            <ul>
              <li>About</li>
              <li>Feature</li>
              <li>Benefits</li>
              <li>Pricing</li>
              <li>Our Client</li>
              <li>Contact Us</li>
              <li>FAQ</li>
            </ul>  
          </div>   */}
            <div className="col-lg-3 offset-lg-1 offset-md-1 col-md-3">
              <div className="widget-lpinfo">
                <h5>Connect with us on</h5>
              </div>
              <div>
                <img src={facebook} width="40px" alt="social" />
                <img src={linkedin} width="40px" alt="social" />
                <img src={instagram} width="40px" alt="social" />
              </div>
            </div>
          </div>
          <div className="row border-top pt-2 pb-2">
            <div className="col-6">
              <p>{new Date().getFullYear()} Copyright © Face2Face Scheduler all rights reserved</p>
            </div>
            <div className="col-6 d-flex justify-content-end">
              <p className="pr-3">Terms & Conditions</p>
              <p>Privacy Policy</p>
            </div>
          </div>
        </div>

        <a href="#" onClick={this.scrollToTop} className="back-to-top">
          <i className="icofont-simple-up"></i>
        </a>
      </section>
    );
  }
}
