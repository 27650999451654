import React from "react";
import Button from "../../Components/Button";
import "./style.scss";

function Price(props) {
  return (
    <div className="col-lg-4 col-md-4">
      <div className="price_box d-flex justify-content-center p-4 mb-3">
        <div className="d-flex align-items-center">
          <div className="">
            <h4 className="heading_color">
              {props.price}
            </h4>
            <hr />
            <h4 className="font-weight-bold">{props.subTitle}</h4>
            {/*<div className="btn btn-success mt-4">
                   Contact Sales
             </div>*/}
            <Button className="d-none" name="Contact Sales" to="contactUs" />
          </div>
        </div>
      </div>
    </div>
  );
}


export default Price;
