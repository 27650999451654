import * as $ from "jquery";

function Main() {
      $(document).scroll(function () {
        var $nav = $(".main-nav");
        $nav.toggleClass('scrolled', $(this).scrollTop() > $nav.height());
      });

    $(document).ready(function () {

        // $('body').scrollspy({
        //     target: ".navbar", offset: 50
        // });   
        

        $("#menu-toggle").click(function(e) {
          e.preventDefault();
          $("#wrapper").toggleClass("toggled");
        });

        $('.nav-toggle').on('click', function () {

            $('.main-navigation').toggleClass('open');
            console.log("click");
            // $('.nav-toggle').toggleClass('fa-bars fa-times');

        });

        $('a[href*="#"]')
            // Remove links that don't actually link to anything
            .not('[href="#"]')
            .not('[href="#0"]')
            .click(function (event) {
                // On-page links
                if (
                    window.location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '') &&
                    window.location.hostname === this.hostname
                ) {
                    // Figure out element to scroll to
                    var target = $(this.hash);
                    target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
                    // Does a scroll target exist?
                    if (target.length) {
                        // Only prevent default if animation is actually gonna happen
                        event.preventDefault();
                        $('html, body').animate({
                            scrollTop: target.offset().top
                        }, 1000, function () {
                            // Callback after animation
                            // Must change focus!
                            var $target = $(target);
                            $target.focus();
                            if ($target.is(":focus")) { // Checking if the target was focused
                                return false;
                            } else {
                                $target.attr('tabindex', '-1'); // Adding tabindex for elements not focusable
                                $target.focus(); // Set focus again
                            };
                        });
                    }
                }
            });
    });
}

export default Main;