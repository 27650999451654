import React from "react";
import Logo from "./../../Images/face2FaceLogo.png";
import LogoMobile from "./../../Images/face2FaceLogo-white.png";
import { animateScroll as scroll } from 'react-scroll';

export default class ListNav extends React.Component {
  scrollToTop() {
    scroll.scrollToTop(100);
  }
  render() {
    return (
      <img
        onClick={this.scrollToTop}
        src={Logo}
        alt="f2f logo"
        className="px-0"
        style={{ width: '15%', marginTop: '-20px', cursor: 'pointer' }}
      />
    );
  }
}
