import React from 'react';
import OwlCarousel from 'react-owl-carousel2';
// import Button from "../../Components/Button";
// import Icon from "./../../Images/play-icon.png";
// import Background from "./../../Images/Banner.jpg";
import handWithPhone from "./../../Images/handWithPhone.png";
import "./style.scss";
import Icofont from 'react-icofont';
// import banner_1 from '../../../assets/images/banner/mobile-1.png';

const Index = () => {

    const options = {
        items: 1,
        nav: true,
        navText: ['<Icofont class="icofont-simple-left" />', '<Icofont class="icofont-simple-right"/>'],
        rewind: true,
        autoplay: true,
        loop: true,
        dots: true,
        autoplayHoverPause: true,
    };

    return (
        <OwlCarousel options={options}>
            <div
                className="d-flex align-items-center element owl-carousel slide-one-item banner-carousel1"
            >
                <div className="container d-none d-lg-block">
                    <div className="row text-left">
                        <div className="col-lg-6 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1" data-aos="fade-up" data-aos-delay="200">

                            <h2 className="ml-5 pl-5 heading_color"
                                style={{
                                    textTransform: 'uppercase',
                                    fontSize: '33px',
                                    lineHeight: '40px'
                                }}
                            >
                                The Leading Face-2-Face Scheduling App for Networking Events
                            </h2>
                            <p className="ml-5 pl-5">
                                The Face-to-Face Scheduler is a specialized app that caters to networking events. 
                                The app helps participants plan their schedules for "Face-2-Face" sessions to get 
                                the most quality and targeted networking meetings from an event.
 							</p>
                            <div className="d-lg-flex ml-5 pl-5">
                                <a href="https://www.youtube.com/watch?v=ezm1HAYr2lM&feature=youtu.be"
                                    className="btn rw-button venobox "
                                    data-vbtype="video"
                                    data-autoplay="true">
                                    <i className="icofont-play-alt-1"></i> WATCH OUR VIDEO
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-6 order-1 order-lg-2 hero-img " data-aos="zoom-in" data-aos-delay="200">
                            <img src={handWithPhone} className="img-fluid banner-1 ml-5 pl-5 mt-5 pt-5" style={{ width: '80%' }} alt="{handWithPhone}" />
                        </div>
                    </div>
                </div>

                <div className="container d-block d-lg-none">
                    <div className="row text-left  pl-3 pr-3">
                        <div className="col-lg-6 pt-5 mt-5" data-aos="fade-up" data-aos-delay="200">
                            <h2 className="heading_color mt-5 pt-5">
                                The Leading Face-2-Face Scheduling App for Networking Events
                            </h2>
                            <p className="">
                                The Face-to-Face Scheduler is a specialized app that caters to networking events. 
                                The app helps participants plan their schedules for "Face-2-Face" sessions to get the most 
                                quality and targeted networking meetings from an event.
 							</p>
                            <a href="https://www.youtube.com/watch?v=ezm1HAYr2lM&feature=youtu.be"
                                className="btn rw-button venobox "
                                data-vbtype="video"
                                data-autoplay="true">
                                <i className="icofont-play-alt-1"></i> WATCH OUR VIDEO
                             </a>
                        </div>
                        <div className="col-lg-6" data-aos="zoom-in" data-aos-delay="200">
                            <img src={handWithPhone} className="img-fluid banner-1 ml-5 pl-5 " style={{ width: '80%' }} alt="{handWithPhone}" />
                        </div>
                    </div>
                </div>
            </div>

            <div
                className="d-flex align-items-center element owl-carousel slide-one-item banner-carousel1"
            >
                <div className="container d-none d-lg-block">
                    <div className="row text-left">
                        <div className="col-lg-6 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1" data-aos="fade-up" data-aos-delay="200">

                            <h2 className="ml-5 pl-5 heading_color"
                                style={{
                                    textTransform: 'uppercase',
                                    fontSize: '33px',
                                    color: 'black',
                                    lineHeight: '40px'
                                }}
                            >
                                The Leading Face-2-Face Scheduling App for Networking Events
                            </h2>
                            <p className="ml-5 pl-5">
                                In addition, it provides an overview of all meetings set and
                                also serves as a tool for participants to review the event agenda. 
                                All participants have all event essentials in their pocket.
 							</p>
                            <div className="d-lg-flex ml-5 pl-5">
                                <a href="https://www.youtube.com/watch?v=ezm1HAYr2lM&feature=youtu.be"
                                    className="btn rw-button venobox "
                                    data-vbtype="video"
                                    data-autoplay="true">
                                    <i className="icofont-play-alt-1"></i> WATCH OUR VIDEO
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-6 order-1 order-lg-2 hero-img " data-aos="zoom-in" data-aos-delay="200">
                            <img src={handWithPhone} className="img-fluid banner-1 ml-5 pl-5 mt-5 pt-5" style={{ width: '80%' }} alt="{handWithPhone}" />
                        </div>
                    </div>
                </div>
                <div className="container d-block d-lg-none">
                    <div className="row text-left  pl-3 pr-3">
                        <div className="col-lg-6 pt-5 mt-5" data-aos="fade-up" data-aos-delay="200">
                            <h2 className="heading_color mt-5 pt-5">
                                The Leading Face-2-Face Scheduling App for Networking Events
                            </h2>
                            <p className="">
                                In addition, it provides an overview of all meetings set and
                                also serves as a tool for participants to review the event agenda. 
                                All participants have all event essentials in their pocket.
 							</p>
                            <a href="https://www.youtube.com/watch?v=ezm1HAYr2lM&feature=youtu.be"
                                className="btn rw-button venobox "
                                data-vbtype="video"
                                data-autoplay="true">
                                <i className="icofont-play-alt-1"></i> WATCH OUR VIDEO
                             </a>
                        </div>
                        <div className="col-lg-6" data-aos="zoom-in" data-aos-delay="200">
                            <img src={handWithPhone} className="img-fluid banner-1 ml-5 pl-5 " style={{ width: '80%' }} alt="{handWithPhone}" />
                        </div>
                    </div>
                </div>
            </div>

        </OwlCarousel>
    )
}

export default Index;
