import React from "react";

function Instructions(props) {
  return (
    <div className="text-center mt-5">
      <div className="" style={{ height: "100px" }}>
        <h4 className="heading_color">
          {props.title}
        </h4>
        <p>{props.subTitle}</p>
      </div>
      <img className="image-phone" src={props.image} alt={props.alt} />
    </div>
  );
}

export default Instructions;
