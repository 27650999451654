import React from "react";
import Price from "./price-box.jsx";
import Button from "../../Components/Button";
import "./style.scss";

export default class Index extends React.Component {
  render() {
    return (
      <section className="text-center dark_bg section pb-5 mb-5" id="pricing">
        <div className="container pb-5 mb-2">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <h1 className="heading_color title mb-4">
                PRICING
            </h1>
            </div>
            <div className="col-lg-12 col-md-12">
              <h2>
                Real Networking Events Version - Traditional Version
            </h2>
              <p>
                Traditional means where people physically go to an event ‘old-school’ style and meet face to face in a networking environment, where tables and time slots are assigned for participants to meet.
            </p>
            </div>
          </div>
          <div className="row mt-3">
            <Price price="USD 1,599" subTitle="up to 100 pax" />
            <Price price="USD 2,499" subTitle="up to 250 pax" />
            <Price price="USD 3,299" subTitle="up to 500 pax" />
          </div>
          <div className="col-lg-12 col-md-12 mt-5">
            <h2>
              Virtual Event Version for Online Networking Events
            </h2>
            <h3 className="heading_color">
              USD 3,499
            </h3>
            <p className="mt-3">
              Virtual networking events are the next big thing; we have a version that will allow scheduling of meetings that prompt participants to call each other on skype/zoom for them to be able to meet virtually. It is also possible that the meeting is held in our own video chat feature.
            </p>
            <Button className="d-none" name="Contact Sales" to="contactUs" />
          </div>
        </div>
      </section>
    );
  }
}
