import React, {useState,useRef} from "react";
import Leonna from "./../../Images/leonna.png";
import axios from "axios";
import { Row, Col } from "react-bootstrap";
import "./style.scss";
import Swal from 'sweetalert2';


function Contacts(props) {


  const [data, setData] = useState({
    full_name: '',
    email: '',
    number: '',
    company: '',
    country: '',
    message: '',
  });

  const updateData = name => e => {
     setData({
       ...data,
      //  errors: {},
       [name]: e.target.value
     })
  }

 
    const handleSubmit=(e)=> {
      e.preventDefault();
      console.log(data)

      let formData = new FormData();
      formData.append('full_name', data.full_name);
      formData.append('email', data.email);
      formData.append('number', data.number);
      formData.append('company', data.company);
      formData.append('country', data.country);
      formData.append('message', data.message);

      const self = this;

      axios({
          method: 'post',
          url: 'https://app.face2facescheduler.com//face2face_api/api/contacts.php',
          // url: 'http://localhost/face2face_api/api/contacts.php',
          data: formData,
          config: { headers: { 'Content-Type': 'multipart/form-data' } }
      })
      .then((response) => {
          if (response.data.status === 'success') {
              Swal.fire({
                  icon: 'success',
                  title: 'Email is successfuly sent!',
                  text: "Thank you for your interest in face2face scheduler. Our Customer Service team will get in touch with you shortly!",
                  showConfirmButton: true
              }).then(function (isConfirm) {
                  if (isConfirm) {
                    setData({
                      full_name: '',
                      email: '',
                      number: '',
                      company: '',
                      country: '',
                      message: '',
                    }); 
                  }
              });
          } else if (response.data.status === 'error') {
              Swal.fire({
                  icon: 'error',
                  title: 'Message not sent!',
                  showConfirmButton: true
              })
          }
      })
      .catch((error) => {
          console.log(error)
          Swal.fire({
              icon: 'error',
              title: 'Message not sent!<br><br> Please try again or contact our admin.',
              showConfirmButton: true
          })
      })
    }


  return (
    <section id="contactUs" className="section dark_bg">
      <div className="container">
        <h1 className="heading_color title mb-4 text-center">
          CONTACTS
        </h1>      
        <div className="row mt-4">
          <div className="col-lg-4 col-md-12 text-center">
              <img 
                  src={Leonna}
                  alt="Leonna"
                  className="img-responsive m-3"
              />
          </div>
          <div className="col-lg-8 col-md-12">
            <div className="row">
              <form
                onSubmit={handleSubmit}
                method="post"
              >
                <fieldset className="row">
                  <Row>
                    <Col lg="12" md="12" sm="12" xs="12">
                      <div className="form-group">
                        <label htmlFor="inputLastname">Full Name</label>
                      <input
                          type="text"
                          className="form-control rw_input"
                          // onBlur={form.handleBlurEvent}
                          onChange={updateData('full_name')}
                          value={data.full_name}
                          required
                        />
                       
                      </div>
                    </Col>
                    <Col lg="6" md="6" sm="6" xs="12">
                      <div className="form-group">
                        <label htmlFor="inputLastname">Email Address</label>
                      <input
                        type="text"
                        onChange={updateData('email')}
                        value={data.email}
                        className="form-control rw_input"
                        required
                      />
                      
                      </div>
                    </Col>
                    <Col lg="6" md="6" sm="6" xs="12">
                      <div className="form-group">
                        <label htmlFor="inputLastname">Contact Number</label>
                        <input
                          type="text"
                          onChange={updateData('number')}
                          value={data.number}
                          className="form-control rw_input"
                          required
                        />
                        
                        </div>
                    </Col>
                    <Col lg="6" md="6" sm="6" xs="12">
                      <div className="form-group">
                        <label htmlFor="inputLastname">Company Name</label>
                        <input
                          type="text"
                          onChange={updateData('company')}
                          value={data.company}
                          className="form-control rw_input"
                          required
                        />
                        
                      </div>
                    </Col>
                    <Col lg="6" md="6" sm="6" xs="12">
                      <div className="form-group">
                        <label htmlFor="inputLastname">Country</label>
                      <input
                        type="text"
                        onChange={updateData('country')}
                        value={data.country}
                        className="form-control rw_input" 
                        required
                      />
                      
                      </div>
                    </Col>
                    <Col lg="12" md="12" sm="12" xs="12">
                      <div className="form-group">
                        <label htmlFor="inputLastname">Your Message</label>                    
                      <textarea
                        className="form-control rw_input"
                        onChange={updateData('message')}
                        value={data.message}
                        required
                      ></textarea>
                      
                      </div>
                    </Col>
                    <Col
                      lg="12"
                      md="12"
                      sm="12"
                      xs="12"
                      className="text-center"
                    >
                      <button
                        type="submit"
                        value="SEND"
                        id="submit"
                        className="btn rw-button mt-4"
                      >
                        Send
                      </button>
                    </Col>
                  </Row>
                </fieldset>
              </form>

                {/*<div className="col-12"> 
                  <Input title="Full Name" id="fullname" name="fullname" placeholder="Your Full Name" 
                  ref={register({
                      required: "Required",
                    })}
                  error={errors.fullname && errors.fullname.message}
                  />
                </div>
                <div className="col-6"> 
                  <Input title="Email" id="email" name="email" placeholder="Your Email Address" 
                  ref={register({
                      required: "Required",
                    })}
                  error={errors.email && errors.email.message}
                  />
                </div>
                <div className="col-6"> 
                  <Input title="Contact Number" id="number" name="number" placeholder="Your Contact Number" 
                  ref={register({
                      required: "Required",
                    })}
                  error={errors.number && errors.number.message}
                  />
                </div>
                <div className="col-6"> 
                  <Input title="Company Name" id="company" name="company" placeholder="Your Company Name" 
                  ref={register({
                      required: "Required",
                    })}
                  error={errors.company && errors.company.message}
                  />
                </div>    
                <div className="col-6"> 
                  <Input title="Country" id="country" name="country" placeholder="Your Country" 
                  ref={register({
                      required: "Required",
                    })}
                  error={errors.country && errors.country.message}
                  />
                </div>                                
                <div className="col-12"> 
                  <Input title="Message" id="msg" name="message" placeholder="Your message here" 
                  ref={register({
                      required: "Required",
                    })}
                  error={errors.msg && errors.msg.message}
                  />
                </div> 
                <div className="col-12 text-center">       
                 <Button type="submit" value="SEND" id="submit" class="d-none" name="Submit"/>
                </div> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}


export default Contacts;
