import React from "react";
import "./style.scss";
import { Link } from 'react-scroll';


function Button(props) {
  return (

    <Link activeClass="active"
      className="btn rw-button mt-4"
      type={props.type}
      value={props.value}
      id={props.id}
      href="#"
      to={props.to}
      spy={true}
      smooth={true}
      offset={-70}
      duration={500}
      isDynamic={true}
      ignoreCancelEvents={false}
    >
      {props.name}
    </Link>
    // <a type={props.type} value={props.value} id={props.id} className="btn rw-button mt-4" href={props.href}>
    //   <img className={props.class} src={props.icon} alt="play icon" /> {props.name}
    // </a>
  );
}

export default Button;