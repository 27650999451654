import React from "react";
import Clients from "./clients.jsx";


export default class Index extends React.Component {
  render() {
    return (
      <section className="section text-center" id="clients">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <h1 className="heading_color title mb-4">
                Our Clients
             </h1>
              {/*<p>
             The Face-2-Face Scheduler App allows for easy administration of Networking Events, Delegates of Networking 
             events can Schedule and Re-schedule their own networking meetings with all participants.
            </p>*/}
            </div>
          </div>
          <div className="">
            <Clients />
          </div>
        </div>
      </section>
    );
  }
}
