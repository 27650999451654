import React from "react";
import { IconContext } from "react-icons";
import { IoIosMenu } from "react-icons/io";
import LogoMobile from "./logo-mobile.jsx";

export default class MobileNav extends React.Component {
	render() {
		return (
			<header style={{ display: "none" }} className="d-block d-lg-none">
				<div className="container-fluid top-nav">
					<div className="row align-items-center">
						<div className="col-12 pb-3" align="left">
							<IconContext.Provider value={{ size: "2.5em", className: "nav-toggle" }}>
								<IoIosMenu style={{ cursor: 'pointer', color: 'white' }} />
							</IconContext.Provider>
							<LogoMobile />
							{/*<i className="fa fa-bars fa-2x nav-toggle text-white"></i>*/}
						</div>
					</div>
				</div>
				<div className="mobile-top">
				</div>
				<nav className=" d-lg-none main-navigation">
					<ul>
						<li><a href="#home">Home</a></li>
						<li><a href="#howItWorks">How it Works</a></li>
						<li><a href="#pricing">Pricing</a></li>
						<li><a href="#testimonials">Our Clients</a></li>
						<li><a href="#contactUs">Contact Us</a></li>
						<li><a href="#download">Download App here</a></li>
					</ul>
				</nav>
			</header>
		);
	}
}
