import React from "react";

function Left(props) {
  return (
      <div className="row">
          <div className="col-lg-6 col-md-6 border-top py-4">
            <img src={props.image} className="w-100 img-responsive" alt={props.alt}/>
        </div>
        <div className="col-lg-6 col-md-6 border-top py-4 d-flex justify-content-center flex-column">
               <h5 className="heading_color">
                 {props.title}
               </h5>
               <p>{props.subTitle}</p>
        </div> 
      </div>
   );
}


export default Left;
