import React from "react";
import Instructions from "./instructions.jsx";
import Download1 from "./../../Images/download-1.png";
import Download2 from "./../../Images/download-2.png";
import Download3 from "./../../Images/download-3.png";
import Download4 from "./../../Images/download-4.png";

export default class Index extends React.Component {
  render() {
    return (
    <section id="download" className="section text-center">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12">
             <h1 className="heading_color title mb-4">
              DOWNLOAD APP
            </h1>
            <p>
             The Face-2-Face Scheduler App allows for easy administration of Networking Events, Delegates of Networking 
             events can Schedule and Re-schedule their own networking meetings with all participants.
            </p>
          </div>
        </div>
        <div className="row">
            <div className="col-lg-6 col-md-6">
                <Instructions key="1" title="Install the App" 
            subTitle={["Click", <strong> Play Store </strong>,"or", <strong> App Store </strong>,"on your mobile devices. Search", <strong> Face2Face Scheduler </strong>,"then click",<strong> Install.</strong>]}
               image={Download1} alt="Download Image" />
            </div>
            <div className="col-lg-6 col-md-6">
                <Instructions key="2" title={<p className="mb-4">Log-in using the details that was sent to your email</p> } 
               image={Download2} alt="Download Image" />
            </div>            
            <div className="col-lg-6 col-md-6">
                <Instructions key="3" title="Update your profile" subTitle="Update your basic information, job title, mobile number, business card and preference"
               image={Download3} alt="Download Image" />
            </div>            
            <div className="col-lg-6 col-md-6">
                <Instructions key="4" title={["Once the account has been set-up, you may now click the event you are joining under", <strong> "My Events"</strong>]} subTitle=""
               image={Download4} alt="Download Image" />
            </div>      
        </div>
      </div>
    </section>
    );
  }
}
