import React from "react";
import Left from "./left.jsx";
import Right from "./right.jsx";
import Mobile from "./mobile.jsx";
import benefit1 from "./../../Images/benefit-1.jpg";
import benefit2 from "./../../Images/benefit-2.jpg";
import benefit3 from "./../../Images/benefit-3.jpg";
import benefit4 from "./../../Images/benefit4.jpg";
import benefit5 from "./../../Images/benefit-5.jpg";
import benefit6 from "./../../Images/benefit-6.jpg";
import benefit7 from "./../../Images/benefit-7.jpg";
import benefit8 from "./../../Images/benefit-8.jpg";
import benefit9 from "./../../Images/benefit-9.jpg";
import benefit10 from "./../../Images/benefit-10.jpg";
import benefit11 from "./../../Images/benefit-11.jpg";
// import data from "./data.json"
/*import Button from "../../Components/Button";*/


export default class Reasons extends React.Component {

  render() {
    return (
      <div>
          <div className="container d-none d-lg-block">
            <div className="col-lg-12 col-md-12">
                  <div className="benefits">
                    <Left image={benefit1} title="Clear and Organized Views of the Event Agenda" subTitle="What participants do with a networking event app is to look at the agenda, really." />
                    <Right image={benefit2} title="Searchable Participants Lists of the Event" subTitle="Lists out all the participants of the event, searchable too" />
                    <Left image={benefit3} title="Messaging" subTitle="Connect participants by a simple messaging tool" />
                    <Right image={benefit4} title="Social Wall" subTitle="Event specific private social media wall where all participants can contribute and engage digitally prior and during the Event " />     
                    <Left image={benefit5} title="Admin Dashboard" subTitle="Of course, from our admin dashboard you have full control over the networking app. From adding participants to view all meeting schedules to be able to support participants on ground, it’s all in the admin dashboard." />     
                    <Right image={benefit6} title="Complete Overview of Event Analytics for Admins" subTitle="As an event administrator you want to see some analysts about your event, here are some sample screens for you to see:" />     
                    <Left image={benefit7} title="Push Notifications" subTitle="Push notifications help prompt participants manage their meetings better, and know if they have a message in the inbox." />     
                  </div>            
            </div>
            <div className="col-lg-12 col-md-12">
                <h3 className="m-3 text-center">
                    Why We Are Better
                </h3>
                  <div className="benefits">
                      <Right image={benefit8} title="It’s in the Pocket: Our App is Mobile" subTitle="Get the most customer engagement when you allow them to see all essentials in their pockets and accessible at all times." />     
                      <Left image={benefit9} title="Participants have Full Control" subTitle="In a global event setting where not all countries are relevant to each other, participants can control which countries they prefer not to set meetings with" />     
                      <Right image={benefit10} title="No Show Board" subTitle="The no-show board allows administrators of a networking event to showcase which participants weren’t able to attend their meetings. With our no-show reporting system participants can report no-shows to the administration and publish it on an event screen for everyone to know who are currently not present." />     
                      <Left image={benefit11} title="Personal Customer Service to Setup the Event and Administer it" subTitle="Our team will help you setup your event from start to finish. If you wish to have on-site support during the event this can also be made possible." />     
                  </div>
              </div>
        </div>
        <div className="container d-block d-lg-none">
          <div className="col-lg-12 col-md-12">
                <div className="benefits">
                  <Mobile image={benefit1} title="Clear and Organized Views of the Event Agenda" subTitle="What participants do with a networking event app is to look at the agenda, really." />
                  <Mobile image={benefit2} title="Searchable Participants Lists of the Event" subTitle="Lists out all the participants of the event, searchable too" />
                  <Mobile image={benefit3} title="Messaging" subTitle="Connect participants by a simple messaging tool" />
                  <Mobile image={benefit4} title="Social Wall" subTitle="Event specific private social media wall where all participants can contribute and engage digitally prior and during the Event " />     
                  <Mobile image={benefit5} title="Admin Dashboard" subTitle="Of course, from our admin dashboard you have full control over the networking app. From adding participants to view all meeting schedules to be able to support participants on ground, it’s all in the admin dashboard." />     
                  <Mobile image={benefit6} title="Complete Overview of Event Analytics for Admins" subTitle="As an event administrator you want to see some analysts about your event, here are some sample screens for you to see:" />     
                  <Mobile image={benefit7} title="Push Notifications" subTitle="Push notifications help prompt participants manage their meetings better, and know if they have a message in the inbox." />     
                </div>            
          </div>
          <div className="col-lg-12 col-md-12">
              <h3 className="m-3 text-center">
                  Why We Are Better
              </h3>
                <div className="benefits">
                    <Mobile image={benefit8} title="It’s in the Pocket: Our App is Mobile" subTitle="Get the most customer engagement when you allow them to see all essentials in their pockets and accessible at all times." />     
                    <Mobile image={benefit9} title="Participants have Full Control" subTitle="In a global event setting where not all countries are relevant to each other, participants can control which countries they prefer not to set meetings with" />     
                    <Mobile image={benefit10} title="No Show Board" subTitle="The no-show board allows administrators of a networking event to showcase which participants weren’t able to attend their meetings. With our no-show reporting system participants can report no-shows to the administration and publish it on an event screen for everyone to know who are currently not present." />     
                    <Mobile image={benefit11} title="Personal Customer Service to Setup the Event and Administer it" subTitle="Our team will help you setup your event from start to finish. If you wish to have on-site support during the event this can also be made possible." />     
                </div>
            </div>

        </div>
      </div>
    );
  }
}
