import React from 'react';
import './App.css';
import Navigation from "./Components/Header/navbar.jsx"
import Header from './Components/Header/index.jsx';
// import Main from './Components/Main/index.jsx';
import MainJs from "./Components/Script/main.jsx";
import HowItWorks from "./Components/HowItWorks/index.jsx";
import Pricing from "./Components/Pricing/index.jsx";
import Testimonials from "./Components/Testimonials/index.jsx";
import Contacts from "./Components/Contacts/index.jsx";
import Download from "./Components/Download/index.jsx";
import Footer from "./Components/Footer/index.jsx";



function App() {
  MainJs();
  return (
    <div className="" id="wrapper">
      <Navigation />
      <Header />
      {/*<Main/>*/}

      <HowItWorks />
      <Pricing />
      <Testimonials />
      <Contacts />
      <Download />
      <Footer />
    </div>
  );
}

export default App;
