import React from "react";
import Benefit1 from "./nativeMobileApp.jsx";
import Setup from "./setup.jsx"
import Face2facePlaceholder from "./../../Images/face2faceVideo.png"
import "./style.scss";

export default class Index extends React.Component {
  render() {
    return (
    <section className="section px-0" id="howItWorks">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12 text-center mb-4">
             <h1 className="heading_color title mb-4">
              HOW IT WORKS
            </h1>
            <p>
            The face to face scheduler is really easy for events administrators and for delegates to use. Here are some of the key features of the app, have a look.
            </p>
              <a href="https://www.youtube.com/watch?v=ezm1HAYr2lM&feature=youtu.be"
                className="venobox "
                data-vbtype="video"
                data-autoplay="true">
                <img className="w-50" src={Face2facePlaceholder} alt="Face2facePlaceholder" />
              </a>
          </div>
        </div>
        <Setup/>
        <Benefit1/>
      </div>
    </section>
    );
  }
}
