import React from "react";

function Mobile(props) {
  return (
      <div className="col-lg-6 col-md-6 border-top py-4">
               <h5 className="heading_color">
                 {props.title}
               </h5>
               <p>{props.subTitle}</p>
            <img src={props.image} className="w-100 img-responsive" alt={props.alt}/>
      </div>
   );
}


export default Mobile;
