import React from "react";
import Left from "./left.jsx";
// import Right from "./right.jsx";
import option1 from "./../../Images/setup_option1.png";
import option2 from "./../../Images/setup_option2.png";
import benefit4 from "./../../Images/benefit4.jpg";
import benefit5 from "./../../Images/benefit5.jpg";
import benefit6 from "./../../Images/benefit6.jpg";
import benefit10 from "./../../Images/benefit10.jpg";
// import data from "./data.json"
/*import Button from "../../Components/Button";*/


export default class Setup extends React.Component {

  render() {
    return (
      <div className="container">
        <div className="col-lg-12 col-md-12 text-center">
          <h3 className="heading_color">How to Setup a Meeting</h3>
          <p>Here is a step by step guide on how participants can setup their meetings and how to view the itinerary</p>

          <h5 className="text-uppercase m-4">To Schedule a meeting, here are two options:</h5>
        </div>
        <div className="row">
          <div className="col-lg-6 col-md-12 d-flex">
            <div className="col-lg-6 col-md-6">
              <img className="w-100" src={option1} />
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="benefits">
                <p><span className="font-weight-bold">Option 1: </span>
                      Under "Find Delegates," search the name & country that you want to set an appointment with. Then, you may click "Schedule a meeting" under the person's name. Select the date and the timeslot that you want. After that, you may now click "Schedule a meeting."</p>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-12 d-flex">
            <div className="col-lg-6 col-md-6">
              <img className="w-100" src={option2} />
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="benefits">
                <p><span className="font-weight-bold">Option 2: </span>
                  Click on "My Schedule." There, you will see the event agenda and meeting timeslots. Click "Schedule a meeting" under the timeslot that you want. Select a delegate whom you want to meet with. Clcik "Schedule a meeting."</p>
              </div>
            </div>
          </div>
        </div>

      </div>
    );
  }
}
