import React from "react";
import List from "./list.jsx";
import { Navbar } from "react-bootstrap";
import MobileNav from "./nav-mobile.jsx";
import { Link, animateScroll as scroll } from 'react-scroll';
import "./style.scss"
import Icofont from "react-icofont";

export default class Nav extends React.Component {
  render() {
    return (
      <nav
        className="top d-flex justify-content-between navbar navbar-expand-lg navbar-dark fixed-top pt-4 main-nav d-none d-lg-block py-2 primary-brand-color"
        id="mainNav"
      >
        <MobileNav />
        <div
          style={{ display: "none" }}
          className="container-fluid d-none d-lg-block"
        >
          <Navbar className="nav-menu pt-0 pb-0" sticky="top">
            <div className="container">
              <List />
              <Navbar.Toggle />
              <Navbar.Collapse style={{ marginTop: '-15px' }} className="justify-content-end nav-btn">
                <Link activeClass="active"
                  className="btn btn-nav"
                  href="#"
                  to="home"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                  isDynamic={true}
                  onSetActive={this.handleSetActive}
                  onSetInactive={this.handleSetInactive}
                  ignoreCancelEvents={false}
                >
                  Home
                </Link>

                <Link activeClass="active"
                  className="btn btn-nav ml-3"
                  href="#"
                  to="howItWorks"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                  isDynamic={true}
                  onSetActive={this.handleSetActive}
                  onSetInactive={this.handleSetInactive}
                  ignoreCancelEvents={false}
                >
                  How it Works
                </Link>

                <Link activeClass="active"
                  className="btn btn-nav ml-3"
                  href="#"
                  to="pricing"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                  isDynamic={true}
                  onSetActive={this.handleSetActive}
                  onSetInactive={this.handleSetInactive}
                  ignoreCancelEvents={false}
                >
                  Pricing
                </Link>

                <Link
                  activeClass="active"
                  className="btn btn-nav ml-3"
                  href="#"
                  to="clients"
                  spy={true}
                  smooth={true}
                  offset={-80}
                  duration={500}
                >
                  Our Clients
                </Link>

                <Link
                  activeClass="active"
                  className="btn btn-nav ml-3"
                  to="contactUs"
                  href="#"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                >
                  <i className="icofont-envelope" style={{ fontSize: '18px' }}></i>
                  &nbsp;Contact Us
                </Link>

                <Link
                  activeClass="active"
                  className="btn btn-primary download-app shadow ml-3"
                  to="download"
                  href="#"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                >
                  <i className="icofont-download" style={{ fontSize: '18px' }}></i>
                  &nbsp;Download App Here
                </Link>


                {/* <Scrollspy items={['home', 'howItWorks', 'pricing', 'testimonials', 'contactUs', 'download']} currentClassName="active">
                  <a href="#home" className="btn btn-nav">Home</a>
                  <a href="#howItWorks" className="btn btn-nav ml-3">How it Works</a>
                  <a href="#pricing" className="btn btn-nav ml-3">Pricing</a>
                  <a href="#testimonials" className="btn btn-nav ml-3">Our Clients</a>
                  <a href="#contactUs" className="btn btn-nav ml-3">Contact Us</a>
                  <a className="btn btn-primary shadow md-menu text-center ml-3"
                    href="#download"
                  >
                    Download App Here
                    </a>
                </Scrollspy> */}
              </Navbar.Collapse>
            </div>
          </Navbar>
        </div>
      </nav>
    );
  }
}
