import React from "react";
import Marquee from "react-marquee-slider";

// import BackgroundSm from "./../../Images/neptue-virtual-sm.jpg";

/*import Navigation from "../../Components/Header/navbar.jsx"
import Sidebar from "./sidebar.jsx";
import ListNav from "./list.jsx";*/




import Background from "./../../Images/Banner.jpg";
import handWithPhone from "./../../Images/handWithPhone.png";

import AirCrargo from "./../../Images/air-cargo.png";
import Ccc from "./../../Images/ccc.png";
import Cln from "./../../Images/cln.png";
import Gaa from "./../../Images/gaa.png";
import Life from "./../../Images/life.png";
import Neptune from "./../../Images/neptune.png";
import Northstar from "./../../Images/north-star.png";
import Waco from "./../../Images/waco.png";
import Tfs from "./../../Images/tfs.png";
import Banner from "../Banner/index";

import "./style.scss";

export default class Header extends React.Component {

	render() {
		return (
			<div className="banner" id="home">
				<Banner />
				<div className="theme-color-1 py-sm-4 py-2 text-white">
					<div className="container">
						<div className="row align-items-center">
							<div className="col-lg-12 col-md-12 col-sm-12 py-3 text-center " align="center">
								<Marquee resetAfterTries={200} velocity={10}>
									<img
										src={AirCrargo}
										alt="logo"
										className="img-responsive"
									/>
									<img
										src={Ccc}
										alt="logo"
										className="img-responsive"
									/>
									<img
										src={Cln}
										alt="logo"
										className="img-responsive"
									/>
									<img
										src={Gaa}
										alt="logo"
										className="img-responsive"
									/>
									<img
										src={Life}
										alt="logo"
										className="img-responsive"
									/>
									<img
										src={Neptune}
										alt="logo"
										className="img-responsive"
									/>
									<img
										src={Northstar}
										alt="logo"
										className="img-responsive"
									/>
									{/* <img
										src={Waco}
										alt="logo"
										className="img-responsive"
									/> */}
									<img
										src={Tfs}
										alt="logo"
										className="img-responsive"
									/>
								</Marquee>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
