import React from "react";
import AirCrargo from "./../../Images/air-cargo.png";
import Ccc from "./../../Images/ccc.png";
import Cln from "./../../Images/cln.png";
import Gaa from "./../../Images/gaa.png";
import Life from "./../../Images/life.png";
import Neptune from "./../../Images/neptune.png";
import Northstar from "./../../Images/north-star.png";
import Waco from "./../../Images/waco.png";
import Tfs from "./../../Images/tfs.png";
/*import ClientSays from "./client-say.jsx";*/

export default class Clients extends React.Component {
   render() {
      return (
         <div className="theme-color-1">
            <div className="container">
               <div className="row align-items-center">
                  <div className="col-lg-12 col-md-12 col-sm-12 text-center " align="center">
                     {/*<h2>
                        Our Clients
                      </h2>*/}
                     {/* <img
                        src={Waco}
                        alt="logo"
                        className="img-responsive m-3"
                     /> */}
                     <img
                        src={AirCrargo}
                        alt="logo"
                        className="img-responsive m-3"
                     />
                     <img
                        src={Ccc}
                        alt="logo"
                        className="img-responsive m-3"
                     />
                     <img
                        src={Cln}
                        alt="logo"
                        className="img-responsive m-3"
                     />
                     <img
                        src={Gaa}
                        alt="logo"
                        className="img-responsive m-3"
                     />
                     <img
                        src={Life}
                        alt="logo"
                        className="img-responsive m-3"
                     />
                     <img
                        src={Neptune}
                        alt="logo"
                        className="img-responsive m-3"
                     />
                     <img
                        src={Northstar}
                        alt="logo"
                        className="img-responsive m-3"
                     />
                     <img
                        src={Tfs}
                        alt="logo"
                        className="img-responsive m-3"
                     />
                  </div>
               </div>
               {/*<div className="mt-3">
                     <h2>
                       What our Clients Say
                     </h2>
                     <div className="row mt-3">
                        <ClientSays logo={Waco} Name="Lorem Ipsum is simply dummy text of the printing and typesetting industry." Details="Lorem Ipsum is simply dummy text of the printing and typesetting industry."/> 
                        <ClientSays logo={AirCrargo} Name="Lorem Ipsum is simply dummy text of the printing and typesetting industry." Details="Lorem Ipsum is simply dummy text of the printing and typesetting industry."/> 
                        <ClientSays logo={Cln} Name="Lorem Ipsum is simply dummy text of the printing and typesetting industry." Details="Lorem Ipsum is simply dummy text of the printing and typesetting industry."/> 
                     </div>
                  </div>   */}
            </div>
         </div>
      );
   }
}
